/* eslint-disable */
import { OBSERVER } from '../formbuilder';

function incrementClone(clone, number) {
  clone.dataset.sectionNumber = number;
  clone.querySelector('#js-repeater-number').innerHTML = number;

  // Incrémentation des 'name' et 'id' des inputs (+ vidage des valeurs)
  const repeated_inputs = clone.querySelectorAll('input:not(#repeaterCount)');
  repeated_inputs.forEach(input => {
    if (input.name.slice(-2) == '[]') {
      input.name = input.name.replace(input.name.slice(-4, -2), `_${number}`);
    } else {
      input.name = input.name.replace(input.name.slice(-2), `_${number}`);
    }
    input.id = input.id.replace(input.id.slice(-2), `_${number}`);

    // Vidage des valeurs clonées
    if (input.type == 'checkbox' || input.type == 'radio') { // Cases
      input.checked = false;
    } else { // Texte
      input.value = '';
    }
  });

  // Incrémentation des 'name' et 'id' des textareas (+ vidage des valeurs)
  const repeated_textareas = clone.querySelectorAll('textarea');
  repeated_textareas.forEach(textarea => {
    textarea.id = textarea.id.replace(textarea.id.slice(-2), `_${number}`);
    textarea.name = textarea.name.replace(textarea.name.slice(-2), `_${number}`);
    textarea.value = '';
    textarea.style.height = null;
  });

  // Incrémentation des 'for' des labels
  const repeated_labels = clone.querySelectorAll('label');
  repeated_labels.forEach(label => {
    const forAttribute = label.getAttribute('for');
    if (forAttribute) {
      label.setAttribute('for', forAttribute.replace(forAttribute.slice(-2), `_${number}`));
    }
  });

  // Incrémentation des 'data-validate-for' des zones d'erreur
  const repeated_validate = clone.querySelectorAll('.c-form-error');
  repeated_validate.forEach(validate => {
    validate.dataset.validateFor = validate.dataset.validateFor.replace(validate.dataset.validateFor.slice(-2), `_${number}`);
  });

  // Si on a des selects, suppression des tail selects pour les réinitialiser comme il faut par la suite (+ vidage des valeurs)
  const tailSelects = clone.querySelectorAll('.tail-select');
  const selects = clone.querySelectorAll('select');
  if (tailSelects.length && selects.length) {
    tailSelects.forEach(tailSelect => {
      tailSelect.remove();
    });
    selects.forEach(select => {
      select.id = select.id.replace(select.id.slice(-2), `_${number}`);
      select.name = select.name.replace(select.name.slice(-2), `_${number}`);
      delete select.dataset.selectHidden;
      delete select.dataset.tailSelect;
      select.style.display = 'block';

      select.selectedIndex = "-1"; // Clear la valeur sélectionnée
    });
  }

  // Si on a un/des champs calendrier, suppression des datepicker wrappers pour les réinitialiser comme il faut par la suite
  const calendars = clone.querySelectorAll('.js-datepickerWrapper');
  if (calendars.length) {
    calendars.forEach(calendar => {
      calendar.remove();
    })
  }

  return clone;
}

function addButton(e) {
  const section = e.target.closest('.js-repeater-section'); // Section d'origine
  let clone = section.cloneNode(true); // Clone de la section
  $('.js-repeater-buttons', section).css("display", "none"); // Retrait du bouton d'ajout sur la section d'origine

  let number = section.dataset.sectionNumber; // Position de la section d'origine
  number++; // Incrémentation

  clone = incrementClone(clone, number); // Mettre le nouvel incrément dans la structure du clone

  $('.js-repeater-remove', clone).css("display", "block"); // Ajout du bouton de suppression sur le clone

  if (clone.dataset.maxSections && (number >= clone.dataset.maxSections)) { // Maximum de section atteint
    $('.js-repeater-add', clone).css("display", "none"); // Retrait du bouton d'ajout
  }

  section.after(clone); // Ajout du clone en dessous de la section d'origine

  removeButtons();
  refreshScripts(`[data-section-number="${number}"]`);
}

function removeButton(e) {
  const section = e.target.closest('.js-repeater-section'); // Section à supprimer
  const prevSection = section.previousSibling; // Section précédente

  section.remove() // Suppression de la section
  $('.js-repeater-buttons', prevSection).css("display", "block");

  removeButtons();
}

function refreshScripts(newSection) {
  initFormBuilder(false, newSection);
}

function removeButtons() {
  OBSERVER.remove('add-button');
  OBSERVER.remove('remove-button');
}

export default function repeaterButtons() {
  OBSERVER.add({
    name: 'add-button',
    event: 'click',
    target: '.js-repeater-add-button',
    function: addButton,
  });
  OBSERVER.on('add-button');

  OBSERVER.add({
    name: 'remove-button',
    event: 'click',
    target: '.js-repeater-remove-button',
    function: removeButton,
  });
  OBSERVER.on('remove-button');
}
