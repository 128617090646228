import { OBSERVER } from '../formbuilder';

export default function limitedCheckboxLists() {
  const onChange = (e) => {
    // Read Choice limit
    const limitedCheckbox = e.currentTarget;
    const limitedCheckboxContainer = limitedCheckbox.closest('.js-limited-chexboxlist');

    let limit = 0;
    if (limitedCheckboxContainer.hasAttribute('data-choice-limit')) {
      limit = limitedCheckboxContainer.dataset.choiceLimit;
    }

    // No Limit Provided.
    if (!limit) return;

    const checkedCheckbox = limitedCheckboxContainer.querySelectorAll('input[type="checkbox"]:checked');
    const notCheckedCheckbox = limitedCheckboxContainer.querySelectorAll('input[type="checkbox"]:not(:checked)');
    const disabledCheckboxLabel = limitedCheckboxContainer.querySelectorAll('.c-checkbox-field__label--disabled');

    if (checkedCheckbox.length >= limit) {
      // Disable all not check
      for (let i = 0; i < notCheckedCheckbox.length; i += 1) {
        notCheckedCheckbox[i].closest('label').classList.add('c-checkbox-field__label--disabled');
      }
    } else {
      // Enable all disabled
      for (let i = 0; i < disabledCheckboxLabel.length; i += 1) {
        disabledCheckboxLabel[i].classList.remove('c-checkbox-field__label--disabled');
      }
    }
  };

  OBSERVER.add({
    name: 'limitedCheckboxLists',
    event: 'change',
    target: '.js-limited-chexboxlist input[type="checkbox"]',
    function: onChange,
  });
  OBSERVER.on('limitedCheckboxLists');
}
